<template>
  <b-container>
    <main>
      <form-wizard
        color="#7367F0"
        :title="null"
        :subtitle="null"
        layout="horizontal"
        finish-button-text="Confirmar"
        next-button-text="Siguiente"
        back-button-text="Regresar"
        class="wizard-vertical mb-3 p-2"
        shape="square"
        step-size="sm"
        @on-complete="onSubmit"
      >
        <tab-content
          title="Información del producto"
          :before-change="validateProductData"
        >
          <validation-observer ref="refProductData">
            <div>
              <h1 v-if="type === 'product'" class="mb-0">Agregar producto</h1>
              <h1 v-else class="mb-0">Agregar servicio</h1>
            </div>

            <!-- Product Name, variant -->
            <div class="d-flex align-items-center mt-2">
              <base-cropper
                :model="productData"
                @cropped-image="productData.logo = $event"
              />
            </div>

            <div class="mt-2">
              <validation-provider
                class="mt-2"
                #default="validationContext"
                name="name"
                rules="required"
              >
                <p>
                  {{
                    type === "product"
                      ? "Nombre del producto"
                      : "Nombre del servicio"
                  }}
                </p>
                <b-form-input
                  id="name"
                  name="name"
                  class="mt-50"
                  v-model="productData.name"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="Galletas"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </div>

            <div v-if="type === 'product'" class="mt-1">
              <validation-provider
                #default="validationContext"
                name="Variedad"
                rules="required"
              >
                <p>Variedad</p>
                <b-form-input
                  class="mt-50"
                  id="variant"
                  name="variant"
                  v-model="productData.variant"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="300gr"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </div>

            <!-- SKU -->
            <div v-if="type === 'product'" class="mt-1">
              <validation-provider
                #default="validationContext"
                name="Código de barras"
                rules=""
              >
                <p>Código de barras</p>

                <b-input-group class="mt-50">
                  <b-form-input
                    name="barcode"
                    v-model="productData.sku"
                    autofocus
                    :state="getValidationState(validationContext)"
                    trim
                    placeholder="Opcional"
                  />
                  <b-input-group-append>
                    <b-button
                      class="mr-1"
                      variant="primary"
                      @click="$bvModal.show('add-product-sku')"
                    >
                      <i class="fas fa-qrcode" />
                    </b-button>
                    <b-modal id="add-product-sku">
                      <StreamBarcodeReader @decode="onDecode" />
                    </b-modal>
                  </b-input-group-append>
                </b-input-group>
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </div>

            <!-- Is product private? -->

            <div v-if="type === 'product'" class="mt-1">
              <p class="d-block">Visibilidad del producto</p>
              <b-button-group size="sm" class="mt-50">
                <b-button
                  :variant="!is_private ? 'success' : 'outline-secondary'"
                  @click="is_private = false"
                >
                  Publico
                </b-button>
                <b-button
                  :variant="is_private ? 'danger' : 'outline-secondary'"
                  @click="is_private = true"
                >
                  Privado
                </b-button>
              </b-button-group>
            </div>

            <div class="mt-1">
              <validation-provider
                #default="validationContext"
                name="Categorías (obligatorio)"
                rules="required"
              >
                <p>Categorías (obligatorio)</p>

                <b-form-tags
                  v-model="productData.categories_ids"
                  size="lg"
                  add-on-change
                  class="mt-50"
                  no-outer-focus
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          :disabled="disabled"
                          variant="primary"
                          class="my-50 mr-25"
                          @remove="removeTag(tag)"
                        >
                          {{ getTagNameById(tag) }}
                        </b-form-tag>
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      :disabled="disabled || availableOptions.length === 0"
                      :options="availableOptions"
                      text-field="name"
                      value-field="id"
                      v-on="inputHandlers"
                    >
                      <template v-slot:first>
                        <!-- This is required to prevent bugs with Safari -->
                        <option disabled value="">
                          Selecciona una categoría...
                        </option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
                <small class="text-danger">
                  {{ validationContext.errors[0] }}
                </small>
              </validation-provider>
            </div>

            <b-row class="mt-1 w-100">
              <b-col>
                <validation-provider
                  #default="validationContext"
                  name="Precio"
                  rules="required"
                >
                  <p>Precio</p>
                  <b-input-group prepend="$">
                    <b-form-input
                      id="price"
                      v-model="unitPrice"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      size="lg"
                      type="number"
                      step="0.01"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </validation-provider>
              </b-col>

              <b-col v-if="type === 'product'">
                <validation-provider
                  #default="validationContext"
                  name="Costo"
                  rules="required"
                >
                  <p>Costo</p>

                  <b-input-group prepend="$">
                    <b-form-input
                      id="cost"
                      v-model="unitCost"
                      autofocus
                      :state="getValidationState(validationContext)"
                      trim
                      size="lg"
                    />

                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-input-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div v-if="type === 'service'" class="mt-1">
              <p>¿Deseas agregar lo que te cuesta a ti tu servicio?</p>
              <b-form-checkbox
                id="has_service_cost"
                v-model="has_service_cost"
                class="custom-control-primary mt-50"
                name="check-button"
                switch
              >
                Sí
              </b-form-checkbox>

              <validation-provider
                v-if="has_service_cost"
                #default="validationContext"
                name="Costo"
                :rules="type === 'service' ? required : null"
              >
                <p>Costo</p>
                <b-form-input
                  id="service_cost"
                  v-model="unitCost"
                  class="mt-50"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="0"
                  type="number"
                  size="lg"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </div>
          </validation-observer>
        </tab-content>

        <tab-content v-if="type === 'product'">
          <b-form-group
            label="¿El producto se vende por peso?"
            label-for="is-weighted"
            label-size="lg"
          >
            <b-button-group
              id="is-weighted"
              v-model="selectedOptionForWeight"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForWeight ? 'success' : 'outline-success'
                "
                @click="handleWeightButton(true)"
              >
                Si
              </b-button>
              <b-button
                :variant="
                  selectedOptionForWeight === false
                    ? 'danger'
                    : 'outline-danger'
                "
                @click="handleWeightButton(false)"
              >
                No
              </b-button>
            </b-button-group>
          </b-form-group>
        </tab-content>

        <tab-content v-if="type === 'product'">
          <b-form-group
            label="¿El producto es comestible?"
            label-for="is-edible"
            label-size="lg"
          >
            <b-button-group
              id="is-edible"
              v-model="selectedOptionForEdible"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForEdible ? 'success' : 'outline-success'
                "
                @click="handleEdibleButton(true)"
                >Si</b-button
              >
              <b-button
                :variant="
                  selectedOptionForEdible === false
                    ? 'danger'
                    : 'outline-danger'
                "
                @click="handleEdibleButton(false)"
              >
                No
              </b-button>
            </b-button-group>
          </b-form-group>
          <div v-if="is_edible === '1'">
            <validation-provider
              #default="validationContext"
              name="Información nutrimental"
              rules="required"
            >
              <b-form-group
                label="¿Cuántos sellos de advertencia alimentaria tiene?"
                label-for="nutritional-info"
              >
                <b-form-input
                  id="nutritional-info"
                  v-model="count"
                  autofocus
                  :state="getValidationState(validationContext)"
                  trim
                  placeholder="0"
                  type="number"
                />

                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </div>
        </tab-content>

        <tab-content>
          <b-form-group
            label="¿El producto o servicio tiene variantes que deseas combinar?"
            label-for="has-variants"
            label-size="lg"
          >
            <b-button-group
              id="has-variants"
              v-model="selectedOptionForVariant"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForVariant ? 'success' : 'outline-success'
                "
                @click="handleVariantButton(true)"
                >Si</b-button
              >
              <b-button
                :variant="
                  selectedOptionForVariant === false
                    ? 'danger'
                    : 'outline-danger'
                "
                @click="handleVariantButton(false)"
                >No</b-button
              >
            </b-button-group>
          </b-form-group>

          <b-form-group v-if="has_variants === '1'" label="Variantes">
            <b-form-tags
              v-model="variants_ids"
              size="lg"
              add-on-change
              no-outer-focus
            >
              <template
                v-slot="{
                  tags,
                  inputAttrs,
                  inputHandlers,
                  disabled,
                  removeTag,
                }"
              >
                <ul
                  v-if="tags.length > 0"
                  class="list-inline d-inline-block mb-1"
                >
                  <li v-for="tag in tags" :key="tag" class="list-inline-item">
                    <b-form-tag
                      :title="tag"
                      :disabled="disabled"
                      variant="primary"
                      class="my-50 mr-25"
                      @remove="removeTag(tag), handleRemoveTag()"
                    >
                      {{ getVariantTagNameById(tag) }}
                    </b-form-tag>
                  </li>
                </ul>
                <b-form-select
                  v-bind="inputAttrs"
                  :disabled="disabled || availableVariants.length === 0"
                  :options="availableVariants"
                  text-field="name"
                  value-field="id"
                  v-on="inputHandlers"
                  @change="resetVariantOptionTags"
                >
                  <template v-slot:first>
                    <option disabled value="">
                      Selecciona las variantes que deseas combinar...
                    </option>
                  </template>
                </b-form-select>
              </template>
            </b-form-tags>
          </b-form-group>

          <b-row v-if="variants_ids.length >= 1" class="d-flex mt-2">
            <b-col>
              <b-form-group label="Opciones de variantes">
                <b-form-tags
                  v-model="variant_option_ids"
                  size="lg"
                  add-on-change
                  no-outer-focus
                >
                  <template
                    v-slot="{
                      tags,
                      inputAttrs,
                      inputHandlers,
                      disabled,
                      removeTag,
                    }"
                  >
                    <ul
                      v-if="tags.length > 0"
                      class="list-inline d-inline-block mb-1"
                    >
                      <li
                        v-for="tag in tags"
                        :key="tag"
                        class="list-inline-item"
                      >
                        <b-form-tag
                          :title="tag"
                          :disabled="disabled"
                          variant="primary"
                          class="my-50 mr-25"
                          @remove="removeTag(tag)"
                        >
                          {{ getVariantOptionTagNameById(tag) }}
                        </b-form-tag>
                      </li>
                    </ul>
                    <b-form-select
                      v-bind="inputAttrs"
                      :disabled="
                        disabled || availableVariantOptions.length === 0
                      "
                      :options="availableVariantOptions"
                      text-field="combination_name"
                      value-field="ids"
                      v-on="inputHandlers"
                    >
                      <template v-slot:first>
                        <option disabled value="">
                          Selecciona las opciones deseadas...
                        </option>
                      </template>
                    </b-form-select>
                  </template>
                </b-form-tags>
              </b-form-group>
            </b-col>
          </b-row>
        </tab-content>

        <tab-content>
          <b-form-group
            label="¿Deseas manejar inventario para este producto o servicio?"
            label-for="has-inventory"
            label-size="lg"
          >
            <b-button-group
              id="has-inventory"
              v-model="selectedOptionForInventory"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForInventory ? 'success' : 'outline-success'
                "
                @click="handleInventoryButton(true)"
                >Si</b-button
              >
              <b-button
                :variant="
                  selectedOptionForInventory === false
                    ? 'danger'
                    : 'outline-danger'
                "
                @click="handleInventoryButton(false)"
                >No</b-button
              >
            </b-button-group>
          </b-form-group>

          <b-row
            v-if="has_inventory === '1' && variant_option_ids.length === 0"
            class="d-flex mt-2"
          >
            <b-col>
              <b-form-group
                label="Ingresa el inventario para el producto"
                label-for="unique-inventory"
              >
                <b-form-input
                  id="unique-inventory"
                  v-model="units"
                  autofocus
                  :state="true"
                  trim
                  placeholder="0"
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <div
            v-if="has_inventory === '1' && variant_option_ids.length > 0"
            class="mt-2"
          >
            <h5 class="mb-0">
              Ingresa el inventario para cada una de las opciones de variantes
            </h5>
            <div v-for="(option, index) in variant_option_ids" :key="index">
              <b-row>
                <b-col size="3" class="d-flex align-items-left" cols="8">
                  <h6>
                    <b-badge pill :variant="'light-info'" class="mt-1">
                      {{ getVariantOptionTagNameById(option) }}
                    </b-badge>
                  </h6>
                </b-col>
                <b-col cols="4">
                  <div>
                    <b-form-input
                      :id="'variant-option-info-' + index"
                      v-model="variant_option_inventory(option).units"
                      autofocus
                      :state="true"
                      trim
                      placeholder="15"
                      size="lg"
                      type="number"
                      @input="updateVariantOptionInventory(option, $event)"
                    />
                  </div>
                </b-col>
              </b-row>
            </div>
          </div>
        </tab-content>

        <tab-content :before-change="validateBundles">
          <p class="text-xl mb-1">Agrega bundles al producto</p>

          <div class="border p-2 rounded">
            <b-form-checkbox
              id="checkbox-1"
              v-model="isBundle"
              name="checkbox-1"
              :value="true"
              :unchecked-value="false"
              inline
            >
              <div class="d-flex flex-column">
                <span> ¿Desea tener bundles (paquetes)? </span>
                <small>
                  Seleccione esta opción si desea agregar bundles al producto.
                </small>
              </div>
            </b-form-checkbox>
          </div>

          <validation-observer v-if="isBundle" ref="refBundles">
            <div
              v-for="(bundle, index) in bundles"
              class="border p-2 rounded mt-2"
              :key="index"
            >
              <ValidationProvider
                v-slot="{ errors }"
                name="Nombre"
                rules="required"
              >
                <b-form-group label="Nombre del bundle">
                  <b-form-input v-model="bundle.name" placeholder="Paquete" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Cantidad"
                rules="required|numeric|min:1"
              >
                <b-form-group label="Cantidad de productos del paquete">
                  <b-form-input
                    v-model="bundle.quantity"
                    type="number"
                    placeholder="1"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                v-slot="{ errors }"
                name="Código QR"
                rules="qrcode"
              >
                <b-form-group label="Código QR (opcional)">
                  <b-form-input
                    v-model="bundle.qr_code"
                    type="text"
                    placeholder="1234567890"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider v-slot="{ errors }" name="SKU" rules="sku">
                <b-form-group label="Código de barras (opcional)">
                  <b-form-input
                    v-model="bundle.sku"
                    type="text"
                    placeholder="ABC1234567"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>

              <ValidationProvider
                v-if="variant_option_ids.length > 0"
                v-slot="{ errors }"
                name="Variante"
                rules="required"
              >
                <b-form-group label="Seleccione una variante">
                  <v-select
                    v-model="bundle.variant_option_id"
                    :options="
                      variant_option_ids.map((id) => ({
                        value: id,
                        label: getVariantOptionTagNameById(id),
                      }))
                    "
                    :reduce="(val) => val.value"
                    class="h-10"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-form-group>
              </ValidationProvider>

              <b-button
                class="mt-2"
                variant="outline-danger"
                @click="removeBundle(index)"
              >
                Eliminar bundle
              </b-button>
            </div>

            <b-button
              class="mt-2"
              variant="outline-secondary"
              @click="addBundle"
            >
              Agregar bundle
            </b-button>
          </validation-observer>
        </tab-content>

        <tab-content>
          <b-form-group
            label="¿Deseas añadir una descripción?"
            label-for="has-description"
            label-size="lg"
          >
            <b-button-group
              id="has-description"
              v-model="selectedOptionForDescription"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForDescription ? 'success' : 'outline-success'
                "
                @click="handleDescriptionButton(true)"
                >Si</b-button
              >
              <b-button
                :variant="
                  selectedOptionForDescription === false
                    ? 'danger'
                    : 'outline-danger'
                "
                @click="handleDescriptionButton(false)"
                >No</b-button
              >
            </b-button-group>
          </b-form-group>
          <div v-if="has_description === '1'">
            <b-form-group
              label="Escribe una descripción detallada..."
              label-for="product_description"
            >
              <b-form-input
                id="product_description"
                v-model="product_description"
                autofocus
                trim
                placeholder="Este producto es para..."
                type="text"
              />
            </b-form-group>
          </div>
        </tab-content>

        <tab-content v-if="type == 'product'">
          <b-form-group
            label="¿Facturas las ventas de este producto?"
            label-for="has-tax"
            label-size="lg"
          >
            <b-button-group
              id="has-tax"
              v-model="selectedOptionForHasTax"
              class="d-flex align-items-center"
            >
              <b-button
                :variant="
                  selectedOptionForHasTax ? 'success' : 'outline-success'
                "
                @click="handleHasTaxButton(true)"
                >Si</b-button
              >
              <b-button
                :variant="
                  !selectedOptionForHasTax ? 'danger' : 'outline-danger'
                "
                @click="handleHasTaxButton(false)"
                >No</b-button
              >
            </b-button-group>
          </b-form-group>
        </tab-content>

        <tab-content :before-change="validateUnitCode">
          <validation-observer ref="refUnitCode">
            <p class="text-xl mb-1">Selecciona la unidad de medida</p>
            <validation-provider
              #default="{ errors }"
              name="Unidad de medida"
              rules="required"
            >
              <v-select
                id="unit-type"
                v-model="unitCode"
                :options="unitCodeOptions"
                :reduce="(val) => val.value"
                label="name"
                class="h-10"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </validation-observer>
        </tab-content>
      </form-wizard>
    </main>
  </b-container>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate"
import { ref } from "@vue/composition-api"
import { mapActions, mapGetters, mapMutations } from "vuex"
import formValidation from "@core/comp-functions/forms/form-validation"
import BaseCropper from "@/@core/components/BaseCropper.vue"
import { StreamBarcodeReader } from "vue-barcode-reader"
import VueSlider from "vue-slider-component"
import "vue-slider-component/theme/default.css"
import vSelect from "vue-select"
import { FormWizard, TabContent } from "vue-form-wizard"
import messagesMixin from "@/@core/mixins/messagesMixin"
import { handleAPIErrors } from "@/utils/fetch-utils"

export default {
  components: {
    // Form Wizard
    FormWizard,
    TabContent,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
    BaseCropper,
    StreamBarcodeReader,
    VueSlider,
    vSelect,
  },
  mixins: [messagesMixin],
  data() {
    return {
      is_private: false,
      type: "",
      step: 1,
      bundles: [
        {
          name: null,
          quantity: 0,
          qr_code: "",
          sku: "",
          variant_option_id: null,
        },
      ],
      isBundle: false,
      count: 0,
      categories: [],
      variants_ids: [],
      variant_option_inventories: {},
      variant_option_ids: [],
      variant_options_combinations: [],
      units: 0,
      unitPrice: 10,
      unitCost: 2,
      loading: false,
      has_inventory: "0",
      has_variants: "0",
      has_description: "0",
      has_tax_response: "0",
      product_description: "",
      is_weighted: "0",
      is_edible: "0",
      has_tax: false,
      has_service_cost: false,
      showDescription: false,
      showFinishButton: false,
      disableContinueButton: true,
      store_product_variants_ids: [],
      selectedOptionForDescription: false,
      selectedOptionForWeight: false,
      selectedOptionForEdible: false,
      selectedOptionForVariant: false,
      selectedOptionForInventory: false,
      selectedOptionForHasTax: false,
      store_products_to_create: [{}],
      unitCode: null,
      fixBackButtonIssue: false,
      taxTypeOptions: [
        {
          name: "IVA",
          value: "IVA",
        },
        {
          name: "IEPS",
          value: "IEPS",
        },
      ],
      unitCodeOptions: [
        {
          name: "Por pieza",
          value: "H87",
        },
        {
          name: "Por kilogramo",
          value: "KGM",
        },
        {
          name: "Por litro",
          value: "LTR",
        },
        {
          name: "Por gramo",
          value: "GRM",
        },
        {
          name: "Por miligramo",
          value: "MGM",
        },
        {
          name: "Por mililitro",
          value: "MLT",
        },
        {
          name: "Por tonelada de carga",
          value: "A75",
        },
      ],
    }
  },

  computed: {
    ...mapGetters("variants", ["variants", "selected_variants"]),

    availableOptions() {
      // eslint-disable-next-line
      return this.categories.filter((opt) => {
        return this.productData.categories_ids.indexOf(opt.id) === -1
      })
    },

    availableVariants() {
      // eslint-disable-next-line
      return this.variants.filter((opt) => {
        return this.variants_ids.indexOf(opt.id) === -1
      })
    },

    availableVariantOptions() {
      return this.variant_options_combinations.filter((opt) => {
        return this.variant_option_ids.indexOf(opt.ids) === -1
      })
    },

    variant_option_inventory() {
      return (optionId) => {
        return this.variant_option_inventories[optionId] || { units: null }
      }
    },

    translatedValue() {
      const letters = ["a", "b", "c", "d", "e"]
      const count = parseInt(this.count)

      if (!isNaN(count) && count >= 0 && count < letters.length) {
        return letters[count]
      } else {
        return "a" // Set a default value if the count is invalid
      }
    },
  },

  setup({ emit }) {
    const blankProductData = {
      name: "patito",
      sku: "",
      logo: null,
      nutritional_info: "a",
      variant: "200gr",
      categories_ids: [],
      is_edible: false,
      is_weighted: false,
      product_type: "product",
    }
    const productData = ref(JSON.parse(JSON.stringify(blankProductData)))
    const resetproductData = () => {
      productData.value = JSON.parse(JSON.stringify(blankProductData))
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetproductData)

    return {
      productData,
      refFormObserver,
      getValidationState,
      resetForm,
      emit,
    }
  },

  watch: {
    variants_ids() {
      const selectedVariants = this.variants.filter((variant) => {
        return this.variants_ids.indexOf(variant.id) !== -1
      })
      this.set_selected_variants(selectedVariants)
      this.setVariantOptions()
    },
  },

  mounted() {
    this.type = this.$route.params.type
    this.fetchCategories({
      by_active_status: true,
      meta: {
        pagination: {
          per_page: 10000,
        },
      },
    }).then((response) => {
      this.categories = response.data
    })
    this.fetchVariants({
      by_active_status: "active",
      meta: {
        pagination: {
          per_page: this.entriesPerPage,
        },
      },
    }).then((response) => {
      this.loading = false
    })
  },

  methods: {
    ...mapActions("products", ["addProduct"]),
    ...mapActions("storeProducts", [
      "addStoreProduct",
      "createBulkStoreProducts",
    ]),
    ...mapActions("categories", ["fetchCategories"]),
    ...mapActions("variants", ["fetchVariants"]),
    ...mapMutations("variants", [
      "set_selected_variants",
      "delete_selected_variants",
    ]),

    updateStoreProductsToCreate() {
      const buildStoreProduct = (inventory, variantOptionIds = []) => {
        const store_product_variants_attributes = variantOptionIds
          .flatMap((id) => id.split(";"))
          .filter(Boolean)
          .map((id) => ({ variant_option_id: id }))

        return {
          store_id: this.$route.params.id,
          is_private: this.is_private,
          unit_price: this.unitPrice,
          unit_cost:
            this.type === "product" || this.has_service_cost
              ? this.unitCost
              : 0,
          has_inventory: inventory ? this.has_inventory === "1" : false,
          has_tax: this.has_tax_response === "1",
          tax_rate: this.has_tax_response === "1" ? 0.16 : 0,
          is_tax_retained: false,
          tax_type: this.has_tax_response === "1" ? "IVA" : "",
          unit_code: this.unitCode,
          price_includes_tax: this.has_tax_response === "1",
          inventory: inventory ? inventory.units : undefined,
          description: this.product_description,
          store_product_variants_attributes,
        }
      }

      this.store_products_to_create =
        this.variant_option_ids.length === 0
          ? [buildStoreProduct({ units: this.units })]
          : this.variant_option_ids
              .map((optionId) =>
                buildStoreProduct(this.variant_option_inventories[optionId], [
                  optionId,
                ])
              )
              .filter(Boolean)

      this.store_products_to_create.forEach((storeProduct) => {
        if (this.variant_option_ids.length > 0) {
          const variant_option_ids =
            storeProduct.store_product_variants_attributes?.map(
              (variant) => variant.variant_option_id
            ) || []

          storeProduct.bundles_attributes = this.bundles.filter((bundle) => {
            const bundleVariantOptionIds = bundle.variant_option_id
              .split(";")
              .filter(Boolean)

            return bundleVariantOptionIds.every((id) =>
              variant_option_ids.includes(id)
            )
          })
        } else {
          storeProduct.bundles_attributes = this.isBundle
            ? [...this.bundles]
            : null
        }
      })
    },

    updateVariantOptionInventory(optionId, value) {
      this.$set(this.variant_option_inventories, optionId, { units: value })
    },

    removeVariantOption(index) {
      this.variant_option_ids.splice(index, 1)
    },

    handleWeightButton(value) {
      this.selectedOptionForWeight = value
      this.is_weighted = value ? "1" : "-1"
    },

    handleEdibleButton(value) {
      this.selectedOptionForEdible = value
      this.is_edible = value ? "1" : "-1"
    },

    handleVariantButton(value) {
      this.selectedOptionForVariant = value
      this.has_variants = value ? "1" : "-1"
    },

    handleInventoryButton(value) {
      this.selectedOptionForInventory = value
      this.has_inventory = value ? "1" : "-1"
    },

    handleDescriptionButton(value) {
      this.selectedOptionForDescription = value
      this.has_description = value ? "1" : "-1"
    },

    handleHasTaxButton(value) {
      this.selectedOptionForHasTax = value
      this.has_tax_response = value ? "1" : "-1"
    },

    resetVariantOptionTags() {
      this.variant_option_ids = []
    },

    setOptionNames() {
      let option_names = []
      this.selected_variants.forEach((variant) => {
        let options = []
        variant.variant_options_attributes.forEach((option) => {
          options.push({ id: option.id, option_name: option.option_name })
        })
        option_names.push(options)
      })
      return option_names
    },

    setVariantOptions() {
      function generateCombinations(
        arraysOfObjects,
        currentIndex = 0,
        currentCombination = "",
        currentIds = "",
        result = []
      ) {
        if (currentIndex === arraysOfObjects.length) {
          result.push({ combination_name: currentCombination, ids: currentIds })
          return
        }

        arraysOfObjects[currentIndex].forEach((option) => {
          const updatedIds = currentIds
            ? `${currentIds};${option.id}`
            : option.id // Update ids string
          generateCombinations(
            arraysOfObjects,
            currentIndex + 1,
            currentCombination.length
              ? `${currentCombination} - ${option.option_name}`
              : option.option_name,
            updatedIds,
            result
          )
        })
      }

      const result = []
      const option_names = this.setOptionNames()
      generateCombinations(option_names, 0, "", [], result)
      this.variant_options_combinations = result
    },

    handleRemoveTag() {
      this.variant_option_ids = []
    },

    handleBack() {
      if (this.step === 2) {
        this.step = 1
      } else if (this.step === 3) {
        this.step = 2
      } else if (this.step === 4 && this.type === "product") {
        this.step = 3
      } else if (this.step === 4 && this.type === "service") {
        this.fixBackButtonIssue = true
        this.step = 1
      } else if (this.step === 5) {
        this.step = 4
      } else if (this.step === 6) {
        this.step = 5
      } else if (this.step === 7) {
        this.step = 6
      }
    },

    onDecode(result) {
      this.productData.sku = result
      this.$bvModal.hide("add-product-sku")
    },

    addBundle() {
      this.bundles.push({
        name: "",
        sku: null,
        qr_code: "",
        quantity: null,
        variant_option_id: null,
      })
    },

    removeBundle(index) {
      this.bundles.splice(index, 1)
    },

    onSubmit() {
      this.unitCode = this.type == "product" ? this.unitCode : "E48"
      this.loading = true
      this.productData.nutritional_info = this.translatedValue
      this.productData.is_edible = this.is_edible === "1" ? true : false
      this.productData.is_weighted = this.is_weighted === "1" ? true : false
      this.productData.product_type = this.type

      this.updateStoreProductsToCreate()

      const obj = {
        product_attributes: this.productData,
        store_product_attributes: this.store_products_to_create,
      }

      this.createBulkStoreProducts(obj)
        .then(() => {
          this.successSwal({
            title: "Producto(s) creado!",
            text: "Producto(s) agregado exitosamente!",
          })

          this.$router.push({
            name: "store-products",
            params: { id: this.$route.params.id },
          })
        })
        .catch((error) => {
          const errors = handleAPIErrors(error.response.data)
          this.errorToast("Error", errors[0])
        })
        .finally(() => {
          this.loading = false
        })
    },

    getTagNameById(id) {
      let name = ""
      this.categories.forEach((x) => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },

    getVariantTagNameById(id) {
      let name = ""
      this.variants.forEach((x) => {
        if (x.id === id) {
          name = x.name
        }
      })
      return name
    },

    getVariantOptionTagNameById(id) {
      let name = ""
      this.variant_options_combinations.forEach((x) => {
        if (x.ids === id) {
          name = x.combination_name
        }
      })
      return name
    },

    handleOptionSelect(selectedOption) {
      this.variant_option_ids.push({ ids: selectedOption.id, units: null })
    },

    // VALIDATIONS

    validateProductData() {
      return new Promise((resolve, reject) => {
        this.$refs.refProductData.validate().then((success) => {
          if (success) {
            if (Number(this.unitCost) > Number(this.unitPrice)) {
              this.errorToast("El costo no puede ser mayor al precio")
              reject(false)
              return
            }

            resolve(true)
          } else {
            this.errorToast("Por favor, llena todos los campos")
            reject(false)
          }
        })
      })
    },

    validateUnitCode() {
      return new Promise((resolve, reject) => {
        this.$refs.refUnitCode.validate().then((success) => {
          if (success) {
            resolve(true)
          } else {
            this.errorToast("Por favor, selecciona una unidad de medida")
            reject(false)
          }
        })
      })
    },

    validateBundles() {
      return new Promise((resolve, reject) => {
        if (this.isBundle) {
          this.$refs.refBundles.validate().then((success) => {
            if (success) {
              const uniqueValues = new Set()
              let isValid = true

              this.bundles.forEach((bundle) => {
                if (
                  //uniqueValues.has(bundle.quantity) ||
                  uniqueValues.has(bundle.qr_code) ||
                  uniqueValues.has(bundle.sku)
                ) {
                  isValid = false
                } else {
                  //uniqueValues.add(bundle.quantity)

                  if (bundle.qr_code) uniqueValues.add(bundle.qr_code)
                  if (bundle.sku) uniqueValues.add(bundle.sku)
                }
              })

              if (!isValid) {
                this.errorToast("Código QR y SKU debe ser único")
                //this.errorToast("Cada cantidad, código QR y SKU debe ser único")
                reject(false)
              } else {
                resolve(true)
              }
              resolve(true)
            } else {
              this.errorToast("Por favor, llena todos los campos")
              reject(false)
            }
          })
        } else {
          resolve(true)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.vue-form-wizard {
  .wizard-header {
    display: none;
  }

  ul.wizard-nav-pills,
  .wizard-progress-with-circle {
    display: none !important;
  }

  .wizard-tab-content {
    padding: 1rem;
  }
}
</style>
